<template>
    <template v-if="token">
        <h2 class="section-header flex flex-row items-end">
            <span>Benachrichtigungsvarianten</span>
            <div class="grow"></div>
            <div class="text-base font-normal flex flex-row items-center flex-row-reverse mt-8">
                <UToggle 
                    v-if="notificationData.config"
                    id="avoidMultipleNotification"
                    class="ml-2 ml-2" 
                    v-model="notificationData.config.avoidMultipleNotification"
                />
                <label for="avoidMultipleNotification" class="cursor-pointer">Mehrfachversand vermeiden, es wird die erste zutreffende Benachrichtigung versandt</label>
            </div>
        </h2>
        <!-- <p>Wie weiter oben schon erwähnt, nutze die Unterteilung bzw. Abgrenzung identischer Benachrichtigungen mit der Varianten-Funktion. Das verhindert mögliche Überschneidungen beim Versand.</p>

        <h2 class="!mt-8 sub-section-header">Varianten-Optionen</h2> -->


        <UAlert 
            v-if="!notificationData.config?.avoidMultipleNotification"
            icon="i-mdi-warning" 
            color="yellow"
            variant="subtle"
            class="mt-4 text-left"
            :ui="{ icon: { base: 'flex-shrink-0 w-8 h-8'}}"
        >
            <template #title>
                Mehrfachversand
            </template>
            <template #description>
                Falls einem Benutzer mehrere Rollen zugewiesen wurden, werden je nach Filterkriterium der Benachrichtigungsfunktion, mehrere Benachrichtigungen der entspr. Vorlage versandt.
            </template>
        </UAlert>

        <UAlert 
            v-if="!notificationData.parent && notificationData._state?.changed"
            icon="i-mdi-translate-variant" 
            color="sky"
            variant="subtle"
            class="mt-4 text-left"
            :ui="{ icon: { base: 'flex-shrink-0 w-8 h-8'}}"
        >
            <template #title>
                neue Varianten
            </template>
            <template #description>
                Du kannst erst andere Variante erstellen, nachdem du die Änderungen der Hauptbenachrichtigung gespeichert hast.
            </template>
        </UAlert>

        <!-- <h2 class="sub-section-header">Weitere Varianten</h2> -->
        <div class="mt-8 mb-4">
            <UButton 
                v-if="!notificationData.parent && !notificationData._state?.changed"
                :disabled="notificationData._state?.changed"
                leading-icon="i-mdi-translate-variant" 
                @click="createNewVariant">
                Daten in neue Variante kopieren
            </UButton>
            <!--<UAlert 
                v-if="!notificationData.parent && notificationData._state?.changed"
                icon="i-mdi-warning" 
                color="primary"
                variant="subtle"
                class="text-left mt-4"
                :ui="{ icon: { base: 'flex-shrink-0 w-8 h-8'}}"
            >
                <template #title>
                    Das geht so nicht
                </template>

                <template #description>
                    Speichere erst deine Änderungen um eine neue Variante zu erstellen.
                </template>
            </UAlert>-->
            <!--<UButton class="mr-4" leading-icon="i-mdi-transfer-left">Benachrichtigung als Sprachvariante zuordnen</UButton>-->
            <!-- <UButton 
                variant="link"
                v-if="notificationData.parent" 
                :to="`./${notificationData.parent}`" 
                leading-icon="i-mdi-link"
            >zur übergeordneten bzw. Master-Benachrichtigung</UButton> -->
        </div>
        <template v-if="advancedTypeOf(notificationData?._siblings).length > 0">
            <UTable 
                class="variantsTable"
                :rows="notificationData?._siblings" 
                :columns="notificationSiblingsColumns" 
                :loading="notificationData?._siblings === null" 
                @select="selectSiblingsRow"
                :ui="{ tr: { base: 'cursor-pointer' }}"
            >
                <template #empty-state>
                    <div class="text-center"><UBadge class="my-12 p-4 text-md">NO_DATA</UBadge></div>
                </template>

                <template #loading-state>
                    <div class="text-center"><UBadge class="my-12 p-4 text-md animate-pulse">LOADING...</UBadge></div>
                </template>

                <template #langRoles-data="{ row }">
                    <div class="flex flex-wrap gap-2">
                        <UTooltip text="Sprache">
                            <UBadge variant="subtle" color="teal">{{ row.lang }}</UBadge>
                        </UTooltip>

                        <template v-for="role in row.roles">
                            <UTooltip text="Benutzerrolle">
                                <UBadge variant="subtle" color="pink" class="uppercase">{{ role }}</UBadge>
                            </UTooltip>
                        </template>

                        <UTooltip text="Hauptbenachrichtigung" v-if="row?.id == notificationData.parent">
                            <UBadge variant="subtle" color="yellow">MASTER</UBadge>
                        </UTooltip>
                    </div>
                </template>

                <template #titleSubject-data="{ row }">
                    <strong>{{ row.title }}</strong><br>
                    <small>{{ row.subject }}</small>
                </template>

                <template #updatedAt-data="{ row }">
                    {{ dayjs(row.updatedAt).format(globals.format.date.default) }}<br>
                    <small>{{ dayjs(row.updatedAt).format(globals.format.time.default) }}</small>
                </template>

                <!--<template #actions-data="{ row }">
                    <UButton :to="`./${row.id}`" variant="ghost" icon="i-mdi-pen" />
                </template>-->
            </UTable>
        </template>
    </template>

    <div vif="notificationData.eijns?.tabs?.find(e => e.selected)?.id == 'default'">
        <h2 class="section-header">
            <span>Benachrichtigungsvorlage</span>
            <template v-if="notificationData.__v && !notificationData.parent || route.params.uuid === 'new'">
                <!--<UBadge color="yellow">MASTER</UBadge>-->
            </template>
            <template v-else-if="route.query.variant === 'new'">
                <!--<UBadge color="primary">neue Variante</UBadge>-->
            </template>
            <template v-else>
                <!--<UBadge color="primary">untergeordnete Variante</UBadge>-->
            </template>
        </h2>
        <p>Die Funktion/Verwendung dient als eindeutiger Index. In Kombination mit der Sprache und den potentiellen Benutzerrollen ergibt sich eine quasi eineindeutige Zuordnung. Falls meherere Vorlagen für die gleiche Funktion vorhanden sind, diese aber nicht eindeutig gekennzeichnet sind, entscheidet die Reihenfolge, also wie das Zeugs aus der Datenbank gelesen wird.<br>
        Du solltest für eine Funktion bestenfalls nur eine Master-Benachrichtigung erstellen, und dann mit weiter detaillierten Untervarianten arbeiten. Bei einer Master-Benachrichtigung, welche untervarianten besitzt, läßt sich die Funktion aufgrund der durchgehenden Verknüpfung zwischen den Varianten, nicht mehr ändern.</p>

        <div class="w-full grid grid-cols-3 gap-8 mt-8">           

            <div>
                <UTooltip :openDelay="500" text="Thumbnail / Vorschau" class="block w-full">
                    <div 
                        class="bg-stone-850 border border-stone-700/30 dark:border-stone-700 w-full h-0 aspect-w-1 aspect-h-1 flex items-stretch bg-cover bg-no-repeat bg-center relative"
                        :style="{backgroundImage: `url(${notificationData.thumbnail})`}"
                    >
                        <UIcon v-if="!notificationData.thumbnail" name="i-mdi-image-text" class="inline-block w-48 h-48 text-stone-700 self-auto m-auto" />
                    </div>
                </UTooltip>
            </div>
            <div class="col-span-2 flex flex-col gap-4">

                <div class="flex flex-row gap-4">

                    <UFormGroup class="w-32" label="Sprache" required>
                        <USelectMenu 
                            v-model="notificationData.lang" 
                            :options="['i18n', ...(globals?.lang || [])]"
                        >
                        </USelectMenu>
                    </UFormGroup>

                    <UFormGroup 
                        class="grow" 
                        :class="(notificationData.parent || route.query.variant == 'new') ? 'opacity-50' : ''" 
                        label="Funktion / Verwendung" 
                        required
                    >
                        <USelectMenu 
                            icon="i-mdi-function"
                            searchable
                            v-model="notificationData.slug" 
                            value-attribute="id"
                            optionAttribute="title"
                            :options="notificationDefs"
                            :disabled="notificationData.parent || route.query.variant == 'new' || notificationData?._siblings?.length > 0"
                            :ui="{ height: 'max-h-96'}"
                            placeholder="Wähle eine nette und passende Funktion..."
                        >
                            <template #label v-if="notificationData.slug">
                                {{ notificationDefs.find(e => e?.id == notificationData.slug)?.title }}
                                <UBadge variant="subtle" class="uppercase">{{ notificationData.slug }}</UBadge>
                            </template>

                            <template #option="{option, selected}">
                                <div class="flex flex-row items-center" :class="`w-${(selected ? 'full pr-3' : 'full pr-8  [calc(100%-48px)]')}`">
                                    <!--<UIcon 
                                        @click.stop.prevent="notificationFunctionHelp(option)"
                                        name="i-mdi-help-box" 
                                        class="opacity-20 hover:opacity-100 cursor-pointer w-6 h-6" 
                                    />-->
                                    <div class="leading-6">
                                        <strong>{{ option.title }}</strong><br>
                                        <span>{{ option.description }}</span>
                                    </div>
                                    <div class="grow"></div>
                                    <div class="flex flex-row gap-2">
                                        <UBadge variant="subtle" class="uppercase">{{ option.id }}</UBadge>
                                        <UTooltip v-if="option.usage" text="Anz. der Verwendungen"><UBadge variant="subtle" color="pink">{{ option.usage }}</UBadge></UTooltip>
                                    </div>
                                </div>
                            </template>
                        </USelectMenu>
                    </UFormGroup>

                    <!--<UFormGroup class="w-1/3" label="Kürzel" required>
                        <UInput 
                            v-model="notificationData.slug"
                            placeholder="gib_ein_kuerzel_ein"
                            @change="notificationData.slug = toSnakeCase(notificationData.slug)"
                        >
                        </UInput>
                    </UFormGroup>-->
                </div>

                <UFormGroup label="Custom slug / Individuelles Kürzel" v-if="notificationData.slug === 'custom'">
                    <UInput 
                        icon="i-mdi-pound-box"
                        v-model="notificationData.custom"
                        placeholder="Gib ein Kürzel ein..."
                        @change="notificationData.custom = doSlug(notificationData.custom)"
                    >
                    </UInput>
                </UFormGroup>

                <UFormGroup label="Auf bestimmte Benutzerrollen beschränken">
                    <USelectMenu 
                        icon="i-mdi-account-supervisor-circle"
                        v-model="notificationData.roles" 
                        :options="roles"
                        multiple
                    >
                        <template #label>
                            <template v-if="!notificationData?.roles?.[0]">
                                <div class="text-sm my-1 opacity-25">keine Rollen zugewiesen...</div>
                            </template>
                            <div v-else class="flex flex-wrap gap-2">
                                <template v-for="(name, index) of notificationData.roles">
                                    <UBadge color="white">
                                        <div class="flex flex-row">
                                            <span class="text-sm whitespace-nowrap">{{ name }}</span>
                                            <UIcon @click.prevent.stop="notificationData.roles.splice(index, 1)" name="i-mdi-close-circle" class="inline-block w-5 h-5 align-middle ml-1 -mr-1 -mb-0.5 text-stone-500/70  hover:text-red-500 cursor-pointer" />
                                        </div>
                                    </UBadge>
                                </template>
                            </div>
                        </template>
                    </USelectMenu>
                </UFormGroup>

                <UAlert 
                    icon="i-mdi-information-variant-box" 
                    color="primary"
                    variant="subtle"
                    class="text-left"
                    :ui="{ icon: { base: 'flex-shrink-0 w-8 h-8'}}"
                >
                    <template #title>
                        Benutzerrollen
                    </template>
                    <template #description>
                        Benachrichtigungen ohne Rollenbeschränkungen, werden an alle Benutzer/Mitarbeiter versandt die durch die möglichen Filterkriterien nicht ausgeschlossen werden! Es ist empfehlenswert, dass Benachrichtigungen immer mindestens eine spezifische Rollenbeschränkung besitzen.
                    </template>
                </UAlert>

                <!-- <h2 class="sub-section-header">Titel / Betreff</h2> -->
                <UFormGroup class="mt-0" label="nicht öffentlicher Titel / interne Bezeichnung" required>
                    <UInput 
                        icon="i-mdi-info-variant-circle"
                        v-model="notificationData.title"
                        placeholder="Gib eine Bezeichnung ein..."
                    >
                    </UInput>
                </UFormGroup>

                <UFormGroup label="Öffentliche Betreffzeile / Nachrichtenüberschrift" required>
                    <UInput 
                        icon="i-mdi-bullhorn"
                        v-model="notificationData.subject"
                        placeholder="Gib einen Betreff ein..."
                    >
                    </UInput>
                </UFormGroup>
            </div>
        </div>

        <!--<template v-if="token">
            <h3 class="mt-24 mb-2 text-lg font-bold uppercase tracking-widest">Benachrichtigung löschen</h3>
            <p>Wenn Du die Benachrichtigung löschen möchtest, ist das mit einem Klick auf den nachfolgenden Button fast erledigt.</p>
            <div class="my-8">
                <UButton 
                    color="red" 
                    class="mr-4" 
                    leading-icon="i-mdi-delete" 
                    @click="confirmDeleteNotification.show = true"
                >
                    Benachrichtigung und untergeordnete Varianten löschen
                </UButton>
            </div>
        </template>-->
    <!--</div>



    <div v-else-if="notificationData.eijns?.tabs?.find(e => e.selected)?.id == 'variants'">-->

        <UModal v-model="notificationFunctionChange.show" persistent>
            <UCard>
                <template #header>
                    <div class="modal-header-wrap">
                        <h3 class="text-base font-semibold leading-6 text-neutral-900 dark:text-white flex flex-row items-center">
                            <UIcon class="w-8 h-8 mr-2" name="i-mdi-progress-question" />
                            Funktionstitel übernehmen
                        </h3>
                        <UButton variant="ghost" icon="i-mdi-close" class="modal-header-close" @click="notificationFunctionChange.show = false" />
                    </div>
                </template>
                <p>Soll die bisherige interne Bezeichnung von <strong class="uppercase">{{ notificationData.title }}</strong> in die der neuen Funktion <strong class="uppercase">{{ notificationFunctionChange?.newVal?.title }}</strong> geändert werden?</p>
                <template #footer>
                    <div class="flex space-x-5">
                        <div class="grow"></div>
                        <UButton @click="notificationFunctionChange.show = false" color="red" variant="ghost" label="Nee, lass mal" />
                        <UButton @click="notificationFunctionChange.callback" color="primary" variant="solid" label="Ja, übernehme es" />
                    </div>
                </template>
            </UCard>
        </UModal>
    </div>
</template>
<script setup>

    import affiliations from '~/composables/user/affiliations.js';
    import baseNotifications from '~/notifications.json'
    import { Icon } from '@iconify/vue';

    import dayjs from 'dayjs'
    import 'dayjs/locale/de'
    dayjs.locale('de')

    const nuxtApp = useNuxtApp()
    const { eijnsJWT, headers } = await useGlobals()
    const appStore = await useAppStore()
    const { globals, roles } = storeToRefs(appStore)

    const route = useRoute()
    const router = useRouter()

    const props = defineProps({
        notificationData: {
            type: Object,
        },
    })

    //const notificationData?._siblings = ref(null)
    const notificationSiblingsColumns = ref([
        {
            key: 'langRoles',
            label: 'Varianten',
        }, 
        {
            key: 'titleSubject',
            label: 'Titel / Betreff',
        }, 
        {
            key: 'updatedAt',
            label: 'Letzte Änderung',
        }, 
    ])

    const selectSiblingsRow = (row) => {
        console.log('row', `/notification/${row.id}`)
        router.push(`/notification/${row.id}`)
        //router.push('/notification/' + row.id)
    }

    const notificationList = {
        ...baseNotifications,
        ...nuxtApp.$eijnsApp.notifications,
    }

    const notificationDefs = computed(() => [
        {
            id: 'custom',
            fullTitle: 'Eine individuelle Vorlage, die nach belieben definiert und beliebig dynamisch eingebunden werden kann',
            title: 'Eine individuelle Vorlage die dynamisch eingebunden werden kann',
        },
        ...Object.entries(notificationList).map(([id, value]) => ({ id, ...value, fullTitle: `${value.title} ${id}` }))
    ]);

    const createNewVariant = (e) => {
        //let newVariant = {...notificationData_template}
        //newVariant.slug = notificationData.slug
        //router.replace('./new', {silent:true})
        //queries.origin = this.userOrigin;
        const filters = {variant: 'new'};

        if (!props.notificationData?._state?.changed) {
            router.push({ ...router.currentRoute, query: { ...route.query, tab: 'default', variant: 'new' } });

            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }

    /* TITEL GGF. UEBERNEHMEN */
    /* HILFEFUKTION */
    const notificationFunctionChange = ref({
        show: false,
        confirmed: false,
        callback: () => {},
    })
    /*const notificationFunctionHelp = (func) => {
        notificationFunctionChange.value.show = true
        notificationFunctionChange.value.help = func
    }*/
    watch(() => props.notificationData.slug, (newVal, oldVal) => {
        console.log(newVal, oldVal)
        console.log(newVal)
        console.log(newVal?.title)
        console.log(route.params.uuid)
        console.log(props.notificationData.title)
        console.log(oldVal?.title)

        const _newVal = notificationDefs.value.find(e => e.id === newVal)
        const _oldVal = notificationDefs.value.find(e => e.id === oldVal)

        /* BEI NEUEN BENACHRICHTIGUNGEN - TITLE AENDERN */
        if (_newVal?.title && route.params.uuid === 'new'
            && (!props.notificationData.title
                || (props.notificationData.title === _oldVal?.title))
        ) {
            console.log('route.params.uuid')
            props.notificationData.title = _newVal?.title

            if (_newVal?.demoData) {
                props.notificationData.config.data = JSON.stringify({ demoData: _newVal?.demoData }, null, 4)
            }
        /* BEI BESTEHENDEN BENACHRICHTIGUNGEN UND AENDERUNG DER FUNKTION - TITLE ANPASSUNG NACHFRAGEN */
        } else if (_newVal?.title && _oldVal?.title 
                   && _newVal?.title !== props.notificationData.title 
                   && !notificationFunctionChange.value.confirmed
        ) {
            notificationFunctionChange.value = {
                show: true,
                oldVal: _oldVal,
                newVal: _newVal,
                callback: () => {
                    props.notificationData.title = _newVal.title
                    notificationFunctionChange.value.show = false
                    notificationFunctionChange.value.confirmed = true
                }
            }
        }
    }, { deep: true })

    /* TITEL AENDERUNG */
    watch(() => props.notificationData.title, (newVal, oldVal) => {
        notificationFunctionChange.value.confirmed = true
    })

    /* CUSTOM SLUG UEBERWACHEN */
    watch(() => props.notificationData.custom, (newVal, oldVal) => {
        //console.log(doSlug(newVal))

        props.notificationData.custom = newVal ? doSlug(newVal) : null
    })

    onBeforeMount(async () => {
    });

    onMounted(async () => {
        // affiliationsList.value = await affiliations('*', globals.value.structure)
    });
</script>
<style type="text/css"> 
    
.variantsTable {
    & td:nth-child(1), 
    & th:nth-child(1) {
        width: 20rem;
    }

    & td:nth-child(3),
    & th:nth-child(3) {
        width: 10rem;
    }
}
</style>