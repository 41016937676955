import CryptoJS from 'crypto-js';

const decode = async (data) => {
    return await CryptoJS.enc.Utf8.stringify(await CryptoJS.AES.decrypt(data, process.env.VERSION_SECRET));
}

const encode = async (data) => {
    return await CryptoJS.AES.encrypt(data, process.env.VERSION_SECRET).toString();
    //return await CryptoJS.enc.Utf8.stringify(await CryptoJS.AES.encrypt(data, process.env.VERSION_SECRET));
}

const random = async (length) => {
    return await CryptoJS.PBKDF2('eijns', await CryptoJS.lib.WordArray.random(128), { keySize: 0.25 * length, iterations: 1000 }).toString();
    //return await CryptoJS.enc.Utf8.stringify(await CryptoJS.AES.encrypt(data, process.env.VERSION_SECRET));
}

const stringRandom = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const token = {
    decode,
    encode,
    random,
    stringRandom,
}